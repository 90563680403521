import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import portfolioItems from "./portfolioData";
import { Link } from "react-router-dom";

const ProjectDetailPage = () => {
  const { category, id } = useParams();

  const allProjects = Object.entries(portfolioItems).flatMap(([cat, items]) =>
    items.map((item) => ({ ...item, category: cat }))
  );
  const project = allProjects.find(
    (item) => item.id.toString() === id && item.category === category
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container className="project-details-container">
        <h2 className="cst-title-2">{project.title}</h2>
        <div className="project-tags">
          {project.tags.map((tag, index) => (
            <span key={index} className="project-tag">
              {tag}
            </span>
          ))}
        </div>
        {project.description.split("\n").map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <div className="project-images">
          {project.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${project.title} - image ${index + 1}`}
            />
          ))}
        </div>
      </Container>
      <section className="text-center pt-4 section-contact">
        {/* <Container>
          <div className="section-contact-box">
            <h1>무엇이 필요하신가요?</h1>
            <p>친절하고 정확한 답변과 견적을 제시해드립니다</p>
            <Link to="/contact">
              <button>견적 확인하기</button>
            </Link>
          </div>
        </Container> */}
      </section>
    </>
  );
};

export default ProjectDetailPage;
