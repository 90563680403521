import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/textlogo_bl.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";

const Comp_Nav = () => {
  return (
    <Navbar expand="sm" fixed="top" className="cst-back-white">
      <Container>
        <Link to="/" className="navbar-brand">
          <img
            src={logo}
            height="30"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown title="개발분야" id="development-dropdown">
              <NavDropdown.Item href="/web">웹페이지</NavDropdown.Item>
              <NavDropdown.Item href="/mobile">모바일앱</NavDropdown.Item>
              <NavDropdown.Item href="/platform">플랫폼</NavDropdown.Item>
              <NavDropdown.Item href="/software">소프트웨어</NavDropdown.Item>
              <NavDropdown.Item href="/research">전문연구분야</NavDropdown.Item>
            </NavDropdown>
            {/* 
            <NavDropdown title="파트너사" id="partners-dropdown">
              <NavDropdown.Item href="https://메로주식회사.com">
                메로주식회사
              </NavDropdown.Item>
              <NavDropdown.Item href="https://엑스홀.com">
                엑스홀
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <NavDropdown title="자사사이트" id="our-sites-dropdown">
              <NavDropdown.Item href="https://reviewssok.shop/">
                리뷰쏙
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <Link to="/contact" className="nav-link cst-ghost-btn">
              상담신청
            </Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Comp_Nav;
