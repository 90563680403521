import pdfsellerapp_1 from "../assets/portfolio/pdfsellerapp_1.jpg";
import pdfsellerapp_2 from "../assets/portfolio/pdfsellerapp_2.jpg";
import colorreader_1 from "../assets/portfolio/colorreader_1.jpg";
import colorreader_2 from "../assets/portfolio/colorreader_2.jpg";
import ocrpdf_1 from "../assets/portfolio/ocrpdf_1.jpg";
import ocrpdf_2 from "../assets/portfolio/ocrpdf_2.jpg";
import ocrpdf_3 from "../assets/portfolio/ocrpdf_3.jpg";
import etc_1 from "../assets/portfolio/etc_1.jpg";
import etc_2 from "../assets/portfolio/etc_2.jpg";
import nh_1 from "../assets/portfolio/nh_1.jpg";
import nh_2 from "../assets/portfolio/nh_2.jpg";
import nh_3 from "../assets/portfolio/nh_3.jpg";
import reviewssok_1 from "../assets/portfolio/reviewssok_1.jpg";
import reviewssok_2 from "../assets/portfolio/reviewssok_2.jpg";
import dm_1 from "../assets/portfolio/dm_1.jpg";
import dm_2 from "../assets/portfolio/dm_2.jpg";
import dm_3 from "../assets/portfolio/dm_3.jpg";
import atisian_1 from "../assets/portfolio/atisian_1.jpg";
import atisian_2 from "../assets/portfolio/atisian_2.jpg";
import turforest_1 from "../assets/portfolio/turforest_1.jpg";
import turforest_2 from "../assets/portfolio/turforest_2.jpg";
import turforest_3 from "../assets/portfolio/turforest_3.jpg";
import petcamapp_1 from "../assets/portfolio/petcamapp_1.jpg";
import junicorn_1 from "../assets/portfolio/junicorn_1.jpg";
import junicorn_2 from "../assets/portfolio/junicorn_2.jpg";

const portfolioItems = {
  mobile: [
    {
      id: 940, //중요도와 동일(높을수록 중요)
      title: "PDF 스캔 / 판매 앱 개발",
      images: [pdfsellerapp_1, pdfsellerapp_2],
      tags: ["MOBILEapp", "Design"],
      description: `PDF 스캔 / 판매 앱 개발`,
    },
    {
      id: 920,
      title: "펫캠 연계 반려동물 알림 스마트앱 개발",
      images: [petcamapp_1],
      tags: ["MOBILEapp", "IoT"],
      description: `펫캠 / 스마트 홈 관련 반려동물 관리어플 제작
      LG 임베디드SW경진대회 수상
      이미지 인식 및 응급상황 알림 기능(토사물 등 인지)
      음성전달기능
      온도 알림기능
      실시간 영상 전송기능
      수면등 ON OFF 제어기능
      반려동물 스케줄 알림 기능
      `,
    },
    {
      id: 910,
      title: "자동차 도색 색상 스캔 클라이언트 개발",
      images: [colorreader_1, colorreader_2],
      tags: ["Python", "IoT", "DB", "PCapp", "MOBILEapp", "Design"],
      description: `안드로이드 및 데스크탑 어플리케이션
  구독자, 유저 관리
  클라우드 데이터베이스 및
  로컬 데이터베이스 관리 프로그램 개발
  색상데이터 처리`,
    },
  ],
  platform: [
    {
      id: 980,
      title: "자동차 도색 색상 스캔 클라이언트 개발",
      images: [colorreader_1, colorreader_2],
      tags: ["Python", "IoT", "DB", "PCapp", "MOBILEapp", "Design"],
      description: `안드로이드 및 데스크탑 어플리케이션
  구독자, 유저 관리
  클라우드 데이터베이스 및
  로컬 데이터베이스 관리 프로그램 개발
  색상데이터 처리`,
    },
    {
      id: 900,
      title: "해외 스타트업 정보 교류 플랫폼 개발",
      images: [junicorn_1, junicorn_2],
      tags: ["Web", "Crawling", "Design"],
      description: `해외 스타트업 정보 교류 플랫폼 개발
  가입 및 회원관리 어드민 기능 개발
  관련 정보 크롤링 및 자동 업로드 기능 개발`,
    },
  ],
  research: [
    {
      id: 950,
      title: "PDF 업로드 및 글자인식 프로그램 개발",
      images: [ocrpdf_1, ocrpdf_2, ocrpdf_3],
      tags: ["OCR", "Python", "Design"],
      description: `PDF 업로드 및 글자인식 PC 프로그램 개발`,
    },
  ],
  software: [
    {
      id: 1000,
      title: "NH 선물 트레이딩 프로그램 개발",
      images: [nh_1, nh_2, nh_3],
      tags: ["Python", "PyQT", "PCapp", "MOBILEapp", "Design"],
      description: `Python기반 실시간 NH 선물 트레이딩 프로그램 개발
          예제, 함수, 라이브러리 Wikidocs 제작
          Youtube 제작
          실사용 샘플 개발`,
    },

    {
      id: 970,
      title: "리뷰 크롤링 시스템 / 랜딩페이지 개발",
      images: [reviewssok_1, reviewssok_2],
      tags: ["Flutter", "Python", "Web", "Design"],
      description: `리뷰 크롤링 서비스 '리뷰쏙' 개발
        리뷰쏙 랜딩 웹페이지 개발
        카톡 상담기능 추가`,
    },
    {
      id: 100,
      title: "기타 개발 프로젝트",
      images: [etc_1, etc_2],
      tags: ["Develop", "Design"],
      description: "상세 설명 1",
    },
  ],
  web: [
    {
      id: 990,
      title: "대명 여행사 레디캐쉬몰 연계 개발",
      images: [dm_1, dm_2, dm_3],
      tags: ["PHP", "Javascript", "DB", "Web"],
      description: `주문내역 관리
          사내 시스템내 결제 / 이니시스 결제 
          PHP & js 웹 페이지 개발`,
    },
    {
      id: 930,
      title: "가구 판매 쇼핑몰페이지 개발",
      images: [atisian_1, atisian_2],
      tags: ["Web", "Javascript", "Shop", "Design"],
      description: `가구 판매용 브랜드 자체 스토어 웹페이지 개발`,
    },
    {
      id: 960,
      title: "퀴즈 커뮤니티페이지 개발",
      images: [turforest_1, turforest_2, turforest_3],
      tags: ["React", "Web", "Design", "Planning"],
      description: `퀴즈 커뮤니티 '바다거북의숲' 기획 디자인 및 개발
        익명 커뮤니티 업로드/댓글 기능 구현`,
    },
  ],
};

portfolioItems.software.push({ ...portfolioItems.research[0] });
portfolioItems.software.push({ ...portfolioItems.platform[0] });
portfolioItems.web.push({ ...portfolioItems.software[1] });

export default portfolioItems;
