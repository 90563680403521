import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import portfolioItems from "./portfolioData";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";

const PortfolioPage = ({ categoryName }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const items = portfolioItems[categoryName];
  const sortedItems = items.sort((a, b) => b.id - a.id);

  const categoryTitles = {
    web: "웹페이지",
    mobile: "모바일앱",
    platform: "플랫폼",
    software: "소프트웨어",
    research: "전문 연구분야",
  };

  const title = categoryTitles[categoryName];

  return (
    <>
      {/* SECTION 1 */}
      <section className="text-center portfolioWeb-page">
        <Container>
          <div className="portfolioWeb-box pb-4">
            <h2 className="cst-title-1 bold ">{title}</h2>
            <h2 className="cst-title-sub-2 cst-grey">
              테크프리가 진행해온 개발 사례를 확인하세요
            </h2>
          </div>
          {sortedItems.map((item) => (
            <Row key={item.id} className="project-row">
              <Col>
                <Link to={`/${categoryName}/${item.id}`}>
                  <div className="project-image-container">
                    <img
                      src={item.images[0]}
                      alt={item.title}
                      className="project-image"
                    />
                    <div className="project-title">{item.title}</div>
                  </div>
                </Link>
              </Col>
            </Row>
          ))}
        </Container>
        <section className="text-center pt-4 section-contact">
          {/* <Container>
            <div className="section-contact-box">
              <h1>무엇이 필요하신가요?</h1>
              <p>친절하고 정확한 답변과 견적을 제시해드립니다</p>
              <Link to="/contact">
                <button>견적 확인하기</button>
              </Link>
            </div>
          </Container> */}
        </section>
      </section>
    </>
  );
};

export default PortfolioPage;
